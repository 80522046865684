import { UAParser } from 'ua-parser-js';

export const AdsType = {
  DESKTOP: 'DESKTOP',
  MOBILE: 'MOBILE',
  ALL: 'ALL',
};

/**
 * Follows how react-useragent checking for device's user agent
 * @return {string[]}
 */
export function getAdsTypeByDevice() {
  if (!window) {
    // fallback to desktop?
    return [AdsType.ALL, AdsType.DESKTOP];
  }

  const uaParser = new UAParser();
  uaParser.setUA(window.navigator.userAgent);

  const isMobileOrTablet = ['mobile', 'tablet'].some(
    (device) => device === uaParser.getDevice().type
  );

  if (isMobileOrTablet) {
    return [AdsType.ALL, AdsType.MOBILE];
  }

  return [AdsType.ALL, AdsType.DESKTOP];
}
