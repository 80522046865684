import { AdsType } from 'lib/ads';

export const HOME_ADS_UNITS = {
  Desktop_HomePage_ATF: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_HomePage_ATF',
    id: 'desktop-homepage-atf-ad',
    dimensions: [
      [1, 1],
      [970, 90],
      [970, 250],
      [728, 90],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595136,
    isCollapseEmptyDivs: false,
  },
  Desktop_Homepage_AdSlot_1: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_Homepage_AdSlot_1',
    id: 'div-gpt-ad-1658231073869-0',
    dimensions: [
      [1, 1],
      [300, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595140,
    isCollapseEmptyDivs: true,
  },
  Desktop_Homepage_AdSlot_2: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_Homepage_AdSlot_2',
    id: 'desktop-homepage-ad-2',
    dimensions: [
      [1, 1],
      [300, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595142,
    isCollapseEmptyDivs: true,
  },
  Desktop_Homepage_AdSlot_3: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_Homepage_AdSlot_3',
    id: 'desktop-homepage-ad-3',
    dimensions: [
      [300, 250],
      [1, 1],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595144,
    isCollapseEmptyDivs: true,
  },
  Desktop_HomePage_BTF: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_HomePage_BTF',
    id: 'desktop-homepage-btf-ad',
    dimensions: [
      [728, 90],
      [1, 1],
      [970, 90],
      [970, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595146,
    isCollapseEmptyDivs: false,
  },

  Mobile_ATF_New: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_ATF_New',
    id: 'mobile-homepage-atf-ad',
    dimensions: [[320, 50]],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582272,
    isCollapseEmptyDivs: false,
  },
  Mobile_AdSlot_1: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_1',
    dimensions: [
      [1, 1],
      [300, 250],
      [300, 600],
    ],
    id: 'mobile-homepage-ad-1',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2543488,
    isCollapseEmptyDivs: true,
  },
  Mobile_AdSlot_2: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_2',
    dimensions: [
      [300, 600],
      [1, 1],
      [300, 250],
    ],
    id: 'mobile-homepage-ad-2',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2543490,
    isCollapseEmptyDivs: true,
  },
  Mobile_AdSlot_3: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_3',
    dimensions: [
      [1, 1],
      [300, 250],
      [300, 600],
    ],
    id: 'mobile-homepage-adslot-3',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582266,
    isCollapseEmptyDivs: true,
  },
  Mobile_BTF: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_BTF',
    dimensions: [[320, 50]],
    id: 'mobile-homepage-btf-ad',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582276,
    isCollapseEmptyDivs: false,
  },
  Mobile_Sticky_BTF: {
    type: AdsType.MOBILE,
    path: '/92492279/Desktop_HomePage_BTF',
    id: 'div-gpt-ad-1652066724445-0',
    dimensions: [
      [320, 100],
      [320, 50],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595146,
    isCollapseEmptyDivs: false,
  },
};

export const ARTICLE_ADS_UNITS = {
  Mobile_ATF_New: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_ATF_New',
    id: 'mobile-article-atf-ad',
    dimensions: [[320, 50]],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582272,
    isCollapseEmptyDivs: false,
  },
  Mobile_Sticky_BTF: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_Sticky_BTF',
    id: 'div-gpt-ad-1652066724445-0',
    dimensions: [
      [320, 100],
      [320, 50],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595146,
    isCollapseEmptyDivs: false,
  },
  Mobile_AdSlot_1: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_1',
    dimensions: ['fluid', [1, 1], [300, 250], [300, 600]],
    id: 'div-gpt-ad-1657259400972-0',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2543488,
    isCollapseEmptyDivs: true,
  },
  Mobile_AdSlot_2: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_2',
    dimensions: ['fluid', [300, 600], [1, 1], [300, 250]],
    id: 'div-gpt-ad-1657259493541-0',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2543490,
    isCollapseEmptyDivs: true,
  },
  Mobile_AdSlot_3: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_3',
    dimensions: ['fluid', [1, 1], [300, 250], [300, 600]],
    id: 'div-gpt-ad-1657259569386-0',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582266,
    isCollapseEmptyDivs: true,
  },
  Mobile_AdSlot_4: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_4',
    dimensions: ['fluid', [1, 1], [300, 250], [300, 600]],
    id: 'div-gpt-ad-1657259632457-0',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582268,
    isCollapseEmptyDivs: true,
  },
  Mobile_AdSlot_5: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_AdSlot_5',
    dimensions: ['fluid', [1, 1], [300, 250], [300, 600]],
    id: 'div-gpt-ad-1657259690836-0',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582270,
    isCollapseEmptyDivs: true,
  },
  Mobile_BTF: {
    type: AdsType.MOBILE,
    path: '/92492279/Mobile_BTF',
    dimensions: [[320, 50]],
    id: 'mobile-article-btf-ad',
    accountId: 22834,
    siteId: 347294,
    zoneId: 2582276,
    isCollapseEmptyDivs: false,
  },
  Desktop_ArticlePage_ATF: {
    type: AdsType.DESKTOP,
    id: 'desktop-in-article-atf',
    path: '/92492279/Desktop_ArticlePage_ATF',
    dimensions: [
      [970, 90],
      [728, 90],
      [970, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595148,
    isCollapseEmptyDivs: false,
  },
  Desktop_ArticlePage_Adslot_1: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_ArticlePage_Adslot_1',
    id: 'div-gpt-ad-1658231402543-0',
    dimensions: [
      [1, 1],
      [300, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595158,
    isCollapseEmptyDivs: true,
  },
  Desktop_ArticlePage_Adslot_2: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_ArticlePage_Adslot_2',
    id: 'div-gpt-ad-1658231482655-0',
    dimensions: [
      [1, 1],
      [300, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595162,
    isCollapseEmptyDivs: true,
  },
  Desktop_ArticlePage_Adslot_3: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_ArticlePage_Adslot_3',
    id: 'div-gpt-ad-1658231545292-0',
    dimensions: [
      [1, 1],
      [300, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595166,
    isCollapseEmptyDivs: true,
  },
  Desktop_ArticlePage_Adslot_4: {
    type: AdsType.DESKTOP,
    path: '/92492279/Desktop_ArticlePage_Adslot_4',
    id: 'div-gpt-ad-1658231620782-0',
    dimensions: [
      [1, 1],
      [300, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595170,
    isCollapseEmptyDivs: true,
  },
  Desktop_RHS_AdSlot_1: {
    type: AdsType.DESKTOP,
    path: '/92492279/RHS_AdSlot_1',
    id: 'rhs-adslot-1',
    dimensions: [
      [1, 1],
      [300, 250],
      [300, 600],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595150,
    isCollapseEmptyDivs: true,
  },
  Desktop_RHS_AdSlot_2: {
    type: AdsType.DESKTOP,
    path: '/92492279/RHS_AdSlot_2',
    id: 'rhs-adslot-2',
    dimensions: [
      [1, 1],
      [300, 250],
      [300, 600],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595154,
    isCollapseEmptyDivs: true,
  },
  Interstitial_Ad: {
    type: AdsType.DESKTOP,
    path: '/92492279/Popup_Interstital_AdSlot',
    id: 'div-gpt-ad-1657260179979-0',
    dimensions: [
      [320, 480],
      [300, 250],
    ],
    accountId: 22834,
    siteId: 347294,
    zoneId: 2595176,
    isCollapseEmptyDivs: true,
  },
};
