function isPrebidJsAvailable() {
  if (!window) {
    console.warn('Please run script in browser.');
    return false;
  }

  if (!window.pbjs) {
    console.warn('Prebid script is missing.');
    return false;
  }

  return true;
}

export function prebidInit() {
  if (!isPrebidJsAvailable()) {
    return false;
  }

  if (window) {
    // disable google tag initial load
    const googletag = window.googletag || {};
    googletag.cmd = googletag.cmd || [];
    googletag.cmd.push(function () {
      googletag.pubads().disableInitialLoad();
      googletag.enableServices();
      console.log('Prebid init');
      // googletag.pubads().disableInitialLoad();
    });
  }
}

export const PREBID_TIMEOUT = 3000;
export const FAILSAFE_TIMEOUT = 120000;

/**
 * @define {bidder: string, params: any} Bidder
 * @define [number, number] Dimension
 * @param adsConfiguration {path: string, dimensions: Dimension[], ?bids: Bidder[]}[]
 * @example prebidAdUnits({sizes: [[300, 250], [300, 600]], path: '/1234567/header-bid-tag-1', bids: []})
 */
export function prebidAdUnits(adsConfiguration = []) {
  if (!isPrebidJsAvailable()) {
    return false;
  }

  const adUnits = adsConfiguration.map((adConfig) => {
    return {
      code: adConfig.path,
      mediaTypes: {
        banner: {
          sizes: adConfig.dimensions,
        },
      },
      bids: adConfig.bids || [
        {
          bidder: 'rubicon',
          params: {
            accountId: 22834,
            siteId: 347294,
            zoneId: adConfig.zoneId,
            inventory: {
              prodtype: ['tech', 'mobile'],
            },
            visitor: {
              ucat: ['new'],
            },
          },
        },
      ],
    };
  });

  function initAdServer() {
    if (window.pbjs.initAdserverSet) return;

    window.pbjs.initAdserverSet = true;

    if (window) {
      // disable google tag initial load
      const googletag = window.googletag || {};
      googletag.cmd = googletag.cmd || [];
      googletag.cmd.push(function () {
        googletag.pubads().disableInitialLoad();
        googletag.enableServices();
        window.pbjs.que.push(function () {
          window.pbjs.setTargetingForGPTAsync();
          googletag.pubads().refresh();
        });
      });
    }
  }

  window.pbjs.que.push(function () {
    window.pbjs.addAdUnits(adUnits);
    window.pbjs.requestBids({
      bidsBackHandler: initAdServer,
      timeout: PREBID_TIMEOUT,
    });
  });
}
